

































import TheFooter from '@/containers/TheFooter.vue'


import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';




@Component({
    components: {
      

    },
})

export default class DashAsideLeft extends Vue {
    private ui = vxm.ui;
    private isOnMobile () {
        return Boolean(getComputedStyle(this.$el).getPropertyValue('--is-mobile'))
    }
}

