


















import TheFooter from '@/containers/TheFooter.vue'


import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';



@Component({
    components: {

    },
})

export default class DashAsideRight extends Vue {
    private ui = vxm.ui;
}

